<template>
  <div class="main">
    <div class="main__background">
        <img class="main__background--img" src="../../assets/textures/naranja.png" alt="paint" />
    </div>
    <div class="main__header">
        <h2 class="main__header--title">{{info.name}}</h2>
        <p class="main__header--subtitle">OBRA ORIGINAL</p>
    </div>
    <div class="main__info">
        <div class="main__info--images">
            <img class="img--main" :src="main" alt="">
            <div class="info__images" >
                <img class="info__images--content" v-for="(image, index) in images" :key="index" :src="image" @click="main = image">
            </div>
        </div>
        <div class="main__info--desc">
            <div class="info--desc">{{info.desc}}</div>
            <div class="info--material">
                <b>MATERIAL:</b>
                <p>{{info.material}}</p>
            </div>
            <div class="info--metrics">
                <b>MEDIDAS:</b>
                <p>{{info.metric}}</p>
            </div>
            <div class="info--price">{{info.price}}</div>
            <div class="info--btn" @click="showForm = true">
                <btn-prim>
                    <template v-slot:text>
                        COMPRAR
                    </template>
                </btn-prim>
            </div>
        </div>
    </div>
    <div class="main__popup">
        <div class="popup__wrapper" v-if="showForm" @close="showForm = false">
            <div class="popup__container">
                <div class="popup__image">
                    <img :src="main" alt="">
                </div>
                <div class="popup__info">
                    <div class="popup__close">
                        <b class="close" @click="showForm = false">X</b>
                    </div>
                    <div class="popup__form">
                        <form action="/buy-mail" method="POST">
                            <div class="first__section">
                                <div class="form__group">
                                    <label for="name">NOMBRE COMPLETO</label>
                                    <input type="text" name="name" placeholder="ZAIRA BANDY" autofocus required>
                                </div>
                                <br>
                                <div class="form__group">
                                    <label for="mail">CORREO ELECTRÓNICO</label>
                                    <input type="mail" name="mail" placeholder="ZAIRABANDY@GMAIL.COM" autofocus required>
                                </div>
                                <br>
                                <div class="form__group--item">
                                    <div class="form__group">
                                        <label for="phone">TELÉFONO</label>
                                        <input type="phone" name="phone" placeholder="55-42-40-60-20" autofocus required>
                                    </div>
                                    <br>
                                    <div class="form__group">
                                        <label for="obra">OBRA</label>
                                        <input type="text" name="obra" value="SOÑANDO DESPIERTA" readonly autofocus>
                                    </div>
                                </div>
                                <br>
                                <div class="form__group--text">
                                    <label for="message">DÉJAME UN MENSAJE</label>
                                    <textarea name="message" id="message" cols="30" rows="10" placeholder="HOLA, ME INTERESA COMPRAR ..."></textarea>
                                </div>
                            </div>
                            <div class="second__section">
                                <btn-prim class="btn__send">
                                    <template v-slot:text>
                                        SOLICITAR COMPRA
                                    </template>
                                </btn-prim>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="main__footer">
        <Footer></Footer>
    </div>
  </div>
</template>

<script>
import Footer from '../../components/Footer.vue';
import BtnPrim from '../../components/Buttons/BtnPrim.vue';

/** IMAGENES */
import D1 from '../../assets/images/Tienda/Despierta/1.jpg';
import D2 from '../../assets/images/Tienda/Despierta/2.jpg';
import D3 from '../../assets/images/Tienda/Despierta/3.jpg';
import D4 from '../../assets/images/Tienda/Despierta/4.jpg';

export default {
    data () {
        return {
            showForm: false,
            info: {
                name: 'SOÑANDO DESPIERTA',
                metric: '60 X 40 CM',
                price: '$' + ' 5,200 ' + 'MXN',
                material: 'ÓLEO SOBRE TELA',
                desc: 'UNA VEZ SOÑÉ ESTA ESCENA. QUE HABÍA UNA MUJER ESPERANDO QUE SE ABRIERA EL CORAZÓN DE SU AMADO. AL PINTARLO, LE AGREGUE DETALLES COMO UN ÁRBOL QUE NO TENÍA HOJAS, UN RELOJ QUE CAMINABA LENTO Y UN CUCHILLO. LA MUJER TIENE PACIENCIA, PERO UNA VEZ QUE SE TERMINE EL TIEMPO, TOMARÁ ACCIÓN Y ALGO HARÁ CON ESE CUCHILLO.'
            },
            images: [ D1, D2, D3, D4 ],
            pageOfItems: [],
            index: null,
            main: D1
        }
    },
    methods: {
      onChangePage(pageOfItems) {
        this.pageOfItems = pageOfItems;
      }
    },
    components: {
        Footer,
        BtnPrim
    }
}
</script>

<style scoped>
.main__background {
  position: relative;
}
.main__background--img {
  width: 100%;
  height: 570px;
  object-fit: cover;
}
.main__header {
  width: 100%;
  position: absolute;
  color: var(--bg-color);
  text-align: center;
  top: 250px;
  margin: auto;
}
.main__header--title{
  font-family: 'Yeseva One';
  font-size: 70px;
  padding: 9px 0px;
  margin: 2px;
}
.main__header--subtitle{
  font-family: var(--font);
  font-weight: 500;
  padding: 9px 0px;
  line-height: 28px;
  margin: 2px;
}

/** DETAILS OF ITEM */
.main__info{
    position: relative;
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    padding: 50px;
    margin-top: -100px;
    justify-content: space-around;
    align-items: center;
}
.main__info--images{
    display: block;
}
.info__images{
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 20px 0px;
}
.info__images--content{
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin: 0px;
    transition: opacity 0.5s;
}
.info__images--content:hover {
    cursor: pointer;
    opacity: 0.5;
}
.img--main {
    width: 800px;
    height: 800px;
    object-fit: cover;
}
.main__info--desc{
    width: 30%;
}
.info--desc{
    font-size: 17px;
    margin: 20px 0px;
    font-weight: 300;
    line-height: 22px;
}
.info--material{
    display: flex;
    margin: 20px 0px;
    align-items: center;
}
.info--metrics{
    display: flex;
    margin: 20px 0px;
    align-items: center;
}
.info--price{
    font-weight: 700;
    font-size: 50px;
    text-align: center;
    padding: 10px;
    margin: 50px 0px;
}
.info--btn{
    display: block;
    text-align: center;
    margin: 50px 0px;
}
b {
    font-size: 15px;
    padding: 5px;
}
p {
    font-size: 15px;
    padding: 5px;
}

/** POP UP */
.popup__wrapper{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
    visibility: visible;
    opacity: 1;
    z-index: 100000;
    text-align: center;
}
.popup__container{
    display: flex;
    flex-wrap: nowrap;    
    align-content: stretch;
    justify-content: space-around;
    background: var(--bk-color);
    width: 70%;
    height: 700px;
    margin: 100px auto;
}
.popup__image{
    width: 50%;
}
.popup__image img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.popup__info{
    width: 50%;
    padding: 50px;
}
.popup__close{
    display: flex;
    justify-content: flex-end;
    font-size: 15px;
    margin-bottom: 20px;
}
.popup__form{
    text-align: left;
}

/** FORM */

label {
    font-size: 10px;
    padding: 15px 0px;
}
input, select {
    width: auto;
    padding: 15px;
    font-size: 10px;
    font-family: inherit;
}
textarea {
    width: auto;
    height: 28px;
    padding: 15px;
    font-size: 10px;
    font-family: inherit;
}
input [name=obra] {
    pointer-events: none;
}
.form__group--item{
    display: flex;
    justify-content: space-between;
}
.form__group{
    display: grid;
}
.form__group--text{
    display: grid;
}
.btn__send{
    margin: 50px 0px;
    text-align: end;
}
.close:hover {
    color: red;
    cursor: pointer;
}
@media (max-width: 800px){
  .main__header--title{
    font-size: 40px;
  }
  .img--main {
    width: 300px;
    height: 300px;
  }
  .info__images--content {
      width: 90px;
      height: 90px;
      margin: 5px;
  }
  .main__info--desc {
      width: auto;
  }
  .popup__image{
      width: auto;
      display: none;
  }
  .popup__info {
      width: auto;
      padding: 10px 0px;
  }
  label {
    font-size: 9px;
    padding: 5px 0px;
  }
    input, select {
        font-size: 9px;
    }
    textarea {
        font-size: 9px;
    }
    .form__group--item {
        display: block;
    }
    .popup__container {
        margin: 20px auto;
        height: -webkit-fill-available;
        flex-wrap: wrap;
    }
}
</style>